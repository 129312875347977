/** @format */

import styled, { css } from 'styled-components';
import { colours, fontSizes, customFonts, responsiveQuery, mobileSearchHeight, zIndexes } from "../../constants/css";
import { Spinner, Button } from "../_global";
var nextIcon = "/static/icons/ico_next_desktop.svg";
export var SearchBoxWrapper = styled.div.withConfig({
  displayName: "style__SearchBoxWrapper",
  componentId: "sc-1izu7em-0"
})(["position:relative;padding-left:17px;background-color:", ";overflow:hidden;display:flex;margin:auto;z-index:", ";i{margin-left:18px;margin-top:0.7rem;padding-right:0.5rem;}@media only screen and ", "{display:inline-flex;background-color:", ";padding-left:0.4rem;width:100%;flex-direction:row;border-radius:3px;margin:0;i{margin-left:auto;}}"], colours.qantasWhite, zIndexes.mobileHeader, responsiveQuery.largerThanMobileNew, colours.qantasWhite);
export var SearchBox = styled.input.withConfig({
  displayName: "style__SearchBox",
  componentId: "sc-1izu7em-1"
})(["border:0;padding:0.5rem 1rem 0.5rem 0.5rem;flex:1;height:", "px;color:", ";&::placeholder{color:", ";@media only screen and ", "{color:", ";}}font-size:", ";background-color:", ";@media only screen and ", "{width:215px;background-color:", ";height:39px;}&:focus{outline:none;}"], mobileSearchHeight, colours.qantasGrey, colours.qantasPlaceHolderLightGrey, responsiveQuery.largerThanMobileNew, colours.qantasPlaceHolderGrey, fontSizes.base, colours.qantasWhite, responsiveQuery.largerThanMobileNew, colours.qantasWhite);
export var SearchResultsContainer = styled.div.withConfig({
  displayName: "style__SearchResultsContainer",
  componentId: "sc-1izu7em-2"
})(["background-color:", ";height:300px;position:absolute;width:100%;z-index:999;display:none;border-top:none;border:solid 1px rgba(151,151,151,0.2);border-left:0;border-right:0;top:100%;overflow-y:auto;overflow-x:hidden;@media only screen and ", "{right:0;top:39px;border-top:0;width:100%;margin-top:6px;border:solid 1px rgba(151,151,151,0.2);}"], colours.qantasWhite, responsiveQuery.largerThanMobileNew);
export var CancelButton = styled(Button).withConfig({
  displayName: "style__CancelButton",
  componentId: "sc-1izu7em-3"
})(["display:none;font-size:12px;padding:0.6rem;background-color:", ";width:95px;margin:0;@media only screen and ", "{display:none;}"], colours.qantasLightGrey, responsiveQuery.largerThanMobileNew);
export var SearchContainer = styled.div.withConfig({
  displayName: "style__SearchContainer",
  componentId: "sc-1izu7em-4"
})(["position:relative;border:0.5px solid ", ";border-left:0;border-right:0;margin-left:auto;@media only screen and ", "{border:0;}", " ", "{", "}", "{", "}", "{", "}"], colours.qantasBorderGrey, responsiveQuery.largerThanMobileNew, function (props) {
  return props.active && "\n      position: absolute;\n      width: 100%;\n\n      @media only screen and ".concat(responsiveQuery.largerThanMobileNew, " {\n        width: calc(100% - 10px);\n        left: 5px;\n        position: absolute;\n      }\n\n      @media only screen and ").concat(responsiveQuery.desktop, " {\n        width: auto;\n        position: relative;\n      }\n  ");
}, SearchBoxWrapper, function (props) {
  return props.active && "\n      @media only screen and ".concat(responsiveQuery.largerThanMobileNew, " {\n        width: calc(100% - 100px);\n      }\n      @media only screen and ").concat(responsiveQuery.desktop, " {\n        width: 360px;\n      }\n      border-bottom-left-radius: 0;\n      border-bottom-right-radius: 0;\n    ");
}, SearchResultsContainer, function (props) {
  return props.active && css(["display:block;padding-top:0.5rem;@media only screen and ", "{padding-top:0;}"], responsiveQuery.mobileNew);
}, CancelButton, function (props) {
  return props.active && "\n      display: inline-block;\n      padding: 0.6rem;\n      vertical-align: top;\n      position: absolute;\n      right: 0;\n      display: none;\n      @media only screen and ".concat(responsiveQuery.largerThanMobileNew, " and ").concat(responsiveQuery.mobile, " {\n        display: inline-block;\n      }\n    ");
});
export var LoadingWrapper = styled.div.withConfig({
  displayName: "style__LoadingWrapper",
  componentId: "sc-1izu7em-5"
})(["position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);text-align:center;color:", ";"], colours.qantasCharcoal);
export var MessageWrapper = styled(LoadingWrapper).withConfig({
  displayName: "style__MessageWrapper",
  componentId: "sc-1izu7em-6"
})(["width:100%;"]);
export var Loading = styled(Spinner).withConfig({
  displayName: "style__Loading",
  componentId: "sc-1izu7em-7"
})([""]);
export var SearchResult = styled.div.withConfig({
  displayName: "style__SearchResult",
  componentId: "sc-1izu7em-8"
})(["> a{display:flex;border-left:7px solid transparent;padding:5px 0;text-decoration:none;margin-bottom:0.1rem;color:", ";&:hover{background-color:rgba(0,9,9,0.03);border-left:7px solid ", ";}}@media only screen and ", "{position:relative;> a{height:4.5rem;padding:0 0 0 1rem;margin:0;border:0;display:flex;align-items:center;&:after{content:'';border-bottom:0.5px solid ", ";position:absolute;display:block;left:4.25rem;width:100%;bottom:0px;}&:hover{background-color:unset;border:0;}}}"], colours.qantasCharcoal, colours.qantasLightAqua, responsiveQuery.mobileNew, colours.qantasBorderLightGrey);
export var MerchantLogo = styled.img.withConfig({
  displayName: "style__MerchantLogo",
  componentId: "sc-1izu7em-9"
})(["width:60px;height:60px;margin-right:1.25rem;@media only screen and ", "{width:48px;height:48px;}"], responsiveQuery.mobileNew);
export var MerchantName = styled.p.withConfig({
  displayName: "style__MerchantName",
  componentId: "sc-1izu7em-10"
})(["max-width:33%;line-height:1.8;white-space:nowrap;@media only screen and ", "{margin:0;font-size:", ";line-height:1.5;font-family:", ";font-weight:500;}"], responsiveQuery.mobileNew, fontSizes.large, customFonts.ciutadellaMed);
export var CalloutWrapper = styled.div.withConfig({
  displayName: "style__CalloutWrapper",
  componentId: "sc-1izu7em-11"
})(["margin-left:auto;display:inline-flex;@media only screen and ", "{display:block;}"], responsiveQuery.mobileNew);
export var MoreArrow = styled.div.withConfig({
  displayName: "style__MoreArrow",
  componentId: "sc-1izu7em-12"
})(["height:6px;width:9px;margin-top:1.5rem;margin-right:13px;background:url('", "') no-repeat;margin-left:1rem;@media only screen and ", "{width:6px;height:10px;background-size:cover;margin:0 21px 0 0;}"], nextIcon, responsiveQuery.mobileNew);
export var SearchSummary = styled.p.withConfig({
  displayName: "style__SearchSummary",
  componentId: "sc-1izu7em-13"
})(["margin:0;padding-left:10px;font-size:", ";font-family:", ";color:", ";font-weight:normal;opacity:0.5;"], fontSizes.small, customFonts.ciutadellaMed, colours.qantasFontGrey);
export var SearchSummaryMobile = styled.p.withConfig({
  displayName: "style__SearchSummaryMobile",
  componentId: "sc-1izu7em-14"
})(["height:2.1875rem;line-height:2.1875rem;margin:0;padding-left:1.125rem;font-family:", ";font-size:", ";border-bottom:0.5px solid ", ";.result-number{color:", ";}.static-text{color:", ";}"], customFonts.ciutadellaReg, fontSizes.small, colours.qantasBorderLightGrey, colours.qantasCharcoal, colours.qantasFontGrey);
export var IconWrapper = styled.div.withConfig({
  displayName: "style__IconWrapper",
  componentId: "sc-1izu7em-15"
})(["display:flex;align-items:center;justify-content:center;background-color:rgba(85,85,85,0.2);border-radius:50%;padding:1.5rem;width:90px;height:90px;margin:0 auto;opacity:0.5;> i{line-height:0.8;}"]);
export var ClearSearch = styled.div.withConfig({
  displayName: "style__ClearSearch",
  componentId: "sc-1izu7em-16"
})(["padding:1rem;cursor:pointer;"]);